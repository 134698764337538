import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { FC } from 'react';
import { Text, TextTypography } from 'wix-ui-tpa/cssVars';
import { classes } from './FormDialogHeader.st.css';

export interface FormDialogHeaderProps {
  selectedDate: string;
  serviceDetails: string;
}

export const FormDialogHeader: FC<FormDialogHeaderProps> = ({
  serviceDetails,
}) => {
  const { t } = useTranslation();

  return (
    <div data-hook="form-dialog-header" className={classes.root}>
      <Text className={classes.title} typography={TextTypography.largeTitle}>
        {t('waitlist-form.form-dialog-header-title')}
      </Text>
      <Text typography={TextTypography.runningText}>{serviceDetails}</Text>
    </div>
  );
};
