import React, { FC } from 'react';
import { Dialog, Divider } from 'wix-ui-tpa/cssVars';
import { classes } from './FormDialog.st.css';
import { FormDialogHeader } from './FormDialogHeader/FormDialogHeader';
import { WaitlistForm, WaitlistFormProps } from './WaitlistForm/WaitlistForm';
import { useAppointmentWaitlistFormActions } from '../hooks/useAppointmentWaitlistFormActions';
import { SuccessDialog } from '../SuccessDialog/SuccessDialog';
import {
  DialogStep,
  DialogViewModel,
} from '../../viewModel/dialogViewModel/dialogViewModel';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  CloseDialogTriggeredBy,
  DialogClosedBy,
} from '../../actions/closeDialog/closeDialog';

export interface FormDialogProps {
  className?: string;
  formViewerContextProps: WaitlistFormProps['formViewerContextProps'];
  viewModel: DialogViewModel;
}

export const FormDialog: FC<FormDialogProps> = ({
  className,
  formViewerContextProps,
  viewModel,
}) => {
  const { closeDialog } = useAppointmentWaitlistFormActions();
  const { t } = useTranslation();

  const dialogRootClasses = `${className} ${classes.root}`;

  const { isOpen, step } = viewModel;

  if (step === DialogStep.SUCCESS) {
    return <SuccessDialog isOpen={isOpen} className={dialogRootClasses} />;
  }

  const { formHeaderViewModel, formViewModel } = viewModel;
  const { selectedDate, serviceDetails } = formHeaderViewModel;

  return (
    <Dialog
      className={dialogRootClasses}
      data-hook="appointment-waitlist-form-dialog"
      isOpen={isOpen}
      onClose={() =>
        closeDialog({
          closedBy: DialogClosedBy.X_BUTTON,
          triggeredBy: CloseDialogTriggeredBy.FORM_MODAL,
        })
      }
      contentClassName={classes.dialogContent}
      childrenWrapperClassName={classes.dialogChildrenWrapper}
      appendToBody
      aria-label={t('waitlist-form.form-dialog-header-title')}
    >
      <FormDialogHeader
        selectedDate={selectedDate}
        serviceDetails={serviceDetails}
      />

      <Divider className={classes.divider} direction="horizontal" />

      <WaitlistForm
        viewModel={formViewModel}
        formViewerContextProps={formViewerContextProps}
      />
    </Dialog>
  );
};
