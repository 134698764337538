import React, { FC } from 'react';
import {
  SectionNotification,
  TextButton,
  TextButtonPriority,
} from 'wix-ui-tpa/cssVars';
import { Trans } from '@wix/yoshi-flow-editor';
import { classes, st } from './WaitlistNotification.st.css';
import { useAppointmentWaitlistFormActions } from '../hooks/useAppointmentWaitlistFormActions';

export interface WaitlistNotificationProps {}

export const WaitlistNotification: FC<WaitlistNotificationProps> = () => {
  const { openDialog } = useAppointmentWaitlistFormActions();

  return (
    <SectionNotification className={st(classes.root)}>
      <SectionNotification.Text className={classes.text}>
        <Trans
          i18nKey="waitlist-form.waitlist-notification-text"
          components={{
            Link: (
              <TextButton
                data-hook="waitlist-form.waitlist-notification-text-link"
                className={classes.textButton}
                priority={TextButtonPriority.primary}
                onClick={() => openDialog()}
                aria-haspopup="true"
              />
            ),
          }}
        ></Trans>
      </SectionNotification.Text>
    </SectionNotification>
  );
};
