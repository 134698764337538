import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { useCreateCssVarsClassContainer } from './useCreateCssVarsClassContainer';
import { WaitlistNotification } from './WaitlistNotification/WaitlistNotification';
import { FormDialog } from './FormDialog/FormDialog';
import { AppointmentWaitlistFormActionsProvider } from './hooks/useAppointmentWaitlistFormActions';
import { AppointmentWaitlistFormViewModel } from '../viewModel/viewModel';
import { AppointmentWaitlistFormActions } from '../actions/actions';

export type ControllerProps = {
  viewModel?: AppointmentWaitlistFormViewModel;
  actions: AppointmentWaitlistFormActions;
};

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const { cssVarsContainerClassName, rootRef } =
    useCreateCssVarsClassContainer();

  const { viewModel, actions } = props;

  if (!viewModel || !actions) {
    return null;
  }

  return (
    <AppointmentWaitlistFormActionsProvider value={actions}>
      <div data-hook="AppointmentWaitlistForm-wrapper" ref={rootRef}>
        <WaitlistNotification />
      </div>

      <FormDialog
        formViewerContextProps={props as any}
        className={cssVarsContainerClassName}
        viewModel={viewModel.dialogViewModel}
      />
    </AppointmentWaitlistFormActionsProvider>
  );
};

export default Widget;
