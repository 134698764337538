import { ViewModelFactory } from '../../types';
import {
  FormHeaderViewModel,
  createFormHeaderViewModel,
} from '../formHeaderViewModel/formHeaderViewModel';
import {
  FormViewModel,
  createFormViewModel,
} from '../formViewModel/formViewModel';

export enum DialogStep {
  FORM,
  SUCCESS,
}

export type FormStepDialogViewModel = {
  isOpen: boolean;
  step: DialogStep.FORM;
  formHeaderViewModel: FormHeaderViewModel;
  formViewModel: FormViewModel;
};

export type SuccessStepDialogViewModel = {
  isOpen: boolean;
  step: DialogStep.SUCCESS;
  formHeaderViewModel?: never;
  formViewModel?: never;
};

export type DialogViewModel =
  | FormStepDialogViewModel
  | SuccessStepDialogViewModel;

export const createDialogViewModel: ViewModelFactory<DialogViewModel> = (
  ...args
) => {
  return {
    isOpen: false,
    step: DialogStep.FORM,
    formHeaderViewModel: createFormHeaderViewModel(...args),
    formViewModel: createFormViewModel(...args),
  };
};
