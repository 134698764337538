import { ActionFactory } from '../../types';
import { SetIsDialogOpen } from '../setIsDialogOpen/setIsDialogOpen';
import { bookingsUoUAppointmentWaitlistFormLoadClicksSrc16Evid1238 } from '@wix/bi-logger-bookings-data/v2';

export enum DialogClosedBy {
  DONE_BUTTON = 'done',
  X_BUTTON = 'close',
}
export enum CloseDialogTriggeredBy {
  THANK_YOU_MODAL = 'thank you modal',
  FORM_MODAL = 'join modal',
}
export type CloseDialog = ({
  closedBy,
  triggeredBy,
}: {
  closedBy: DialogClosedBy;
  triggeredBy: CloseDialogTriggeredBy;
}) => void;

export const createCloseDialog: ActionFactory<
  CloseDialog,
  { setIsDialogOpen: SetIsDialogOpen }
> =
  ({ setIsDialogOpen, flowAPI: { bi } }) =>
  ({ closedBy, triggeredBy }) => {
    setIsDialogOpen(false);

    bi?.report(
      bookingsUoUAppointmentWaitlistFormLoadClicksSrc16Evid1238({
        component: triggeredBy,
        action: closedBy,
      }),
    );
  };
