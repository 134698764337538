import React, { FC } from 'react';
import { Button, Dialog, Text, TextTypography } from 'wix-ui-tpa/cssVars';
import { st, classes } from './SuccessDialog.st.css';
import {
  Trans,
  useBi,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useAppointmentWaitlistFormActions } from '../hooks/useAppointmentWaitlistFormActions';
import {
  CloseDialogTriggeredBy,
  DialogClosedBy,
} from '../../actions/closeDialog/closeDialog';
import { bookingsUoUAppointmentWaitlistFormLoadClicksSrc16Evid1238 } from '@wix/bi-logger-bookings-data/v2';

export interface SuccessDialogProps {
  className?: string;
  isOpen: boolean;
}

export const SuccessDialog: FC<SuccessDialogProps> = ({
  isOpen,
  className,
}) => {
  const { closeDialog } = useAppointmentWaitlistFormActions();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();

  const createOnClose = (closedBy: DialogClosedBy) => () => {
    closeDialog({
      closedBy,
      triggeredBy: CloseDialogTriggeredBy.THANK_YOU_MODAL,
    });
  };

  React.useEffect(() => {
    const reportLoadedBi = () => {
      bi.report(
        bookingsUoUAppointmentWaitlistFormLoadClicksSrc16Evid1238({
          component: 'thank you modal',
          action: 'load',
        }),
      );
    };

    reportLoadedBi();
  }, []);

  return (
    <Dialog
      className={className}
      isOpen={isOpen}
      onClose={createOnClose(DialogClosedBy.X_BUTTON)}
      appendToBody
      aria-label={t('waitlist-form.form-dialog-success-title')}
    >
      <div
        className={st(classes.root, {
          isMobile,
        })}
      >
        <Text typography={TextTypography.largeTitle}>
          {t('waitlist-form.form-dialog-success-title')}
        </Text>
        <Text className={classes.text} typography={TextTypography.runningText}>
          <Trans
            i18nKey="waitlist-form.form-dialog-success-text"
            components={{
              br: <br />,
            }}
          />
        </Text>
        <Button
          onClick={createOnClose(DialogClosedBy.DONE_BUTTON)}
          fullWidth={isMobile}
        >
          {t('waitlist-form.form-dialog-success-button')}
        </Button>
      </div>
    </Dialog>
  );
};
