import React from 'react';
import { AppointmentWaitlistFormActions } from '../../actions/actions';

export const AppointmentWaitlistFormActionsContext =
  React.createContext<AppointmentWaitlistFormActions>(
    {} as AppointmentWaitlistFormActions,
  );

export const AppointmentWaitlistFormActionsConsumer =
  AppointmentWaitlistFormActionsContext.Consumer;
export const AppointmentWaitlistFormActionsProvider =
  AppointmentWaitlistFormActionsContext.Provider;

export const useAppointmentWaitlistFormActions = () =>
  React.useContext(AppointmentWaitlistFormActionsContext);
