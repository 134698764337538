import { useEffect, useRef, useState } from 'react';

export const useCreateCssVarsClassContainer = () => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [cssVarsContainerClassName, setCssVarsContainerClassName] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (rootRef.current) {
      const calendarWrapper = rootRef.current.closest(
        '[data-hook="AppointmentWaitlistForm-wrapper"]',
      );
      if (calendarWrapper?.parentElement) {
        setCssVarsContainerClassName(calendarWrapper?.parentElement.className);
      }
    }
  }, []);

  return { rootRef, cssVarsContainerClassName };
};
