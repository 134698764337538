import React, { FC, useCallback, useRef, useState } from 'react';
import { useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { FormValues, FormError } from '@wix/form-viewer';
import {
  FormViewer,
  FormViewerContext,
  FormViewerHandle,
} from '@wix/form-viewer/widget';
import { useAppointmentWaitlistFormActions } from '../../hooks/useAppointmentWaitlistFormActions';
import { FormViewModel } from '../../../viewModel/formViewModel/formViewModel';
import { classes } from './WaitlistForm.st.css';
import { bookingsUoUAppointmentWaitlistFormLoadClicksSrc16Evid1238 } from '@wix/bi-logger-bookings-data/v2';

export type WaitlistFormProps = {
  viewModel: FormViewModel;
  formViewerContextProps: Parameters<typeof FormViewerContext>[0];
};

export const WaitlistForm: FC<WaitlistFormProps> = ({
  viewModel: { formId, initialValues, fieldsOverride },
  formViewerContextProps,
}) => {
  const { onFormSubmitSuccess } = useAppointmentWaitlistFormActions();
  const { i18n } = useTranslation();
  const formViewer = useRef<FormViewerHandle>(null);
  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const bi = useBi();

  const handleFormChange = useCallback(
    (values: FormValues) => setFormValues(values),
    [],
  );

  const handleFormValidate = useCallback((errors: FormError[]) => {
    setFormErrors(errors);
  }, []);

  const reportFormSubmitted = () => {
    bi.report(
      bookingsUoUAppointmentWaitlistFormLoadClicksSrc16Evid1238({
        component: 'join waitlist',
        action: 'click',
      }),
    );
  };

  React.useEffect(() => {
    const reportLoadedBi = () => {
      bi.report(
        bookingsUoUAppointmentWaitlistFormLoadClicksSrc16Evid1238({
          component: 'join modal',
          action: 'load',
        }),
      );
    };

    reportLoadedBi();
  }, []);

  return (
    <div className={classes.root}>
      <FormViewerContext {...formViewerContextProps}>
        <FormViewer
          onChange={handleFormChange}
          onValidate={handleFormValidate}
          values={formValues}
          errors={formErrors}
          i18n={i18n}
          formId={formId}
          ref={formViewer}
          overrides={fieldsOverride}
          bi={bi}
          onSubmitSuccess={onFormSubmitSuccess}
          onSubmit={reportFormSubmitted}
        />
      </FormViewerContext>
    </div>
  );
};
