import {
  StyleParamType,
  wixColorParam,
  createStylesParams,
  wixFontParam,
} from '@wix/tpa-settings';

export type IStylesParams = {
  notificationBackgroundColor: StyleParamType.Color;
  notificationLinkTextColor: StyleParamType.Color;
  textFont: StyleParamType.Font;
};

export default createStylesParams<IStylesParams>({
  notificationBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.05),
  },
  notificationLinkTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) =>
      wixFontParam('Body-M', {
        size: isMobile ? 14 : 16,
      })({ textPresets }),
  },
});
